<template>


  <!-- Confirm Candidate Modal -->
  <div class="modal fade" id="confirmCandidateModal" tabindex="-1"
       aria-labelledby="confirmCandidateModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmCandidateModalLabel">Кандидат</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="my-3">

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Выберите тип</label>
              <div class="col-md-9">
                <select class="form-control form-select"
                        v-model="contract.contract_type">
                  <option v-for="(contractType, contractTypeIndex) in [{name: 'Выберите тип'}, ...contractTypes]"
                          :key="contractTypeIndex"
                          :value="contractType?.id">
                    {{ contractType.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Выберите срок</label>
              <div class="col-md-9">
                <select class="form-control form-select"
                        v-model="contract.contract_term">
                  <option v-for="(contractTerm, contractTermIndex) in [{name: 'Выберите срок'}, ...contractTerms]"
                          :key="contractTermIndex"
                          :value="contractTerm?.term">
                    {{ contractTerm.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label for="work_start_date" class="col-md-3 col-form-label">
                Дата трудового договора
              </label>
              <div class="col-md-9">
                <input id="work_start_date" type="date" class="form-control"
                       v-model="contract.work_start_date">
              </div>
            </div>

            <div class="form-group row mt-4">
              <label for="contract_start_date" class="col-md-3 col-form-label">
                С даты
              </label>
              <div class="col-md-9">
                <input id="contract_start_date" type="date" class="form-control"
                       v-model="contract.contract_start_date">
              </div>
            </div>

            <div class="form-group row mt-4">
              <label for="contract_end_date" class="col-md-3 col-form-label">
                До даты
              </label>
              <div class="col-md-9">
                <input id="contract_end_date" type="date" class="form-control"
                       v-model="contract.contract_end_date">
              </div>
            </div>

          </div>
          <div class="col-md-12 mt-4">
            <div class="col-md-3">
              <label for="comment">Комментарий:</label>
            </div>
            <div class="col-md-9">
          <textarea id="comment" class="form-control" v-model="confirmCandidateComment">
          </textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="confirmCandidate()">
            Потвердить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Отменить
          </button>

        </div>
      </div>
    </div>
  </div>
  <!-- End Select Kkk Interview Modal -->

</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";


  export default {
    name: "ConfirmCandidateModal",
    data() {
      return {
        page: +this.$route.query.page || 1,
        confirmCandidateComment: '',
        contractTypes: [
          {
            id: 1,
            name: 'Штатный'
          },
          {
            id: 2,
            name: 'Совместитель'
          },
          {
            id: 3,
            name: 'Докторантура'
          },
          {
            id: 4,
            name: 'Гпх'
          },
        ],
        contractTerms: [
          {
            term: 1,
            name: '1 год'
          },
          {
            term: 2,
            name: '2 года'
          },
          {
            term: 3,
            name: '3 года'
          },
          {
            term: 4,
            name: '1 семестр'
          }
        ],
        contract: {
          contract_type: 1,
          work_start_date: new Date().toISOString().slice(0, 10),
          contract_start_date: new Date().toISOString().slice(0, 10),
          contract_end_date: null,
          user_id: null,
          contract_term: 1
        }
      }
    },
    computed: {
      ...mapState('vacancy', ['userSyllabus']),
    },
    watch: {
      'contract.contract_term'() {
        if (this.contract.contract_term == 1) {
          this.contract.contract_end_date = `${new Date().getFullYear() + 1}-08-31`
        } else if (this.contract.contract_term == 2) {
          this.contract.contract_end_date = `${new Date().getFullYear() + 2}-08-31`
        } else if (this.contract.contract_term == 3) {
          this.contract.contract_end_date = `${new Date().getFullYear() + 3}-08-31`
        } else {
          let month = new Date().getMonth()
          let countMonth = month + 6 + 1
          if (countMonth <= 12) {
            this.contract.contract_end_date = `${new Date().getFullYear()}-${new Date().getMonth() + 6}-${new Date().getDate()}`
          } else {
            this.contract.contract_end_date = `${new Date().getFullYear() + 1}-0${countMonth - 12}-${new Date().getDate()}`
          }
        }
      }
    },
    methods: {
      ...mapActions('vacancy', ['GET_USER_SYLLABUS', 'PUT_USER_SYLLABUS_CONFIRM_STATUS']),
      //...mapMutations('vacancy', ['UPDATE_CANDIDATE_COMMENT_DATA']),


      getFio(user) {
        return `${user.lastname || ''} ${user.firstname || ''} ${user.middlename || ''}`
      },
      async confirmCandidate() {
        const form = {
          comment: this.confirmCandidateComment,
          ...this.contract
        }

        await this.PUT_USER_SYLLABUS_CONFIRM_STATUS(form)

        let discipline_vacancy_id = this.userSyllabus[0].discipline_vacancy_id;
        await this.GET_USER_SYLLABUS({page: this.page, discipline_vacancy_id})
      }

    },

    async mounted() {

    }
  }
</script>

<style scoped>

</style>
