<template>
  <div class="container">
    <div class="vacancy-applications-form mt-4">
      <button type="button" @click="$router.go(-1)" class="my-4 btn btn-primary">Назад</button>
      <h4 class="text-center">Список откликов на вакансию</h4>

      <div v-if="getDecryptedRoles().includes('departmentHead') || getDecryptedRoles().includes('admin')">
        <DepartmentHeadVacancyApplications/>
      </div>

      <div v-if="getDecryptedRoles().includes('HRHead') || getDecryptedRoles().includes('admin')">
        <HrVacancyApplications/>
      </div>

    </div>
  </div>
</template>
<script>

  import DepartmentHeadVacancyApplications from '@/components/vacancy/DepartmentHeadVacancyApplications.vue'
  import HrVacancyApplications from '@/components/vacancy/HrVacancyApplications.vue'
  import {mapActions, mapState} from "vuex";
  import {getDecryptedRoles} from "@/utils/helpers/decryptedRoles.helpers";


  export default {
    name: "VacancyApplications",
    components: {
      DepartmentHeadVacancyApplications,
      HrVacancyApplications
    },

    data() {
      return {
        page: +this.$route.query.page || 1,
      }
    },
    computed: {
      ...mapState('vacancy', ['userSyllabus']),
    },

    methods: {
      getDecryptedRoles,
      ...mapActions('vacancy', ['GET_USER_SYLLABUS']),
    },
    async mounted() {

      let discipline_vacancy_id = this.$route.query.discipline_vacancy_id || 0
      let page = 1
      await this.GET_USER_SYLLABUS({page, discipline_vacancy_id})

    }
  }
</script>

<style scoped>

</style>