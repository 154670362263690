<template>

  <ResumeInfoModal v-bind:id="'ResumeInfoModal'"/>
  <ConfirmCandidateModal v-bind:id="'ConfirmCandidateModal'"/>
  <ConfirmWithoutInterviewCandidateModal v-bind:id="'ConfirmWithoutInterviewCandidateModal'"/>
  <RejectCandidateModal v-bind:id="'RejectCandidateModal'"/>

  <div class="col-md-3">
    <label for="filterStatus">Фильтрация по статусу</label>
    <select class="form-control form-select" id="filterStatus"
            @change="changeFilterStatus">
      <option
        v-for="(item, index) in [{ name: 'Все', status_name: 'all'}, {name: 'Одобренные', status_name: 'confirmedStatus'}, {name: 'Отклоненные', status_name: 'rejectedStatus'}]"
        :value="item.status_name"
        :key="index">{{item.name}}
      </option>
    </select>
  </div>

  <!-- table start -->
  <div class="table-responsive-md mt-4">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th scope="col">№</th>
        <th scope="col">ФИО</th>
        <th scope="col">Название дисциплины</th>
        <th scope="col">Резюме</th>
        <th scope="col">Принять/отклонить</th>
        <th scope="col">Принять ускоренно</th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="(item,index) in filteredUserSyllabus" :key="index">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ getFio(item.user0) }}</td>
        <td>{{ item.disciplineVacancy.discipline.name }}</td>
        <td>
          <!--          <button type="button" class="btn btn-primary" data-bs-toggle="modal"-->
          <!--                  data-bs-target="#ResumeInfoModal" @click="openResumeInfoModal(item.user)">-->
          <!--            Посмотреть резюме-->
          <!--          </button>-->
          <a @click="goToUser(item.user)" class="link-style">
            резюме
          </a>
        </td>
        <td>
          <div v-if="!+item.without_kkk">
            <div v-if="item.reject_status==1">
              <p class="text-danger">Заявка отклонена</p>
            </div>
            <div v-else-if="item.confirm_status==1">
              <p class="text-success">Заявка принята</p>
            </div>
            <div v-else>
              <button type="button" class="btn btn-success" data-bs-toggle="modal"
                      data-bs-target="#ConfirmCandidateModal" @click="openConfirmCandidateModal(item.id)">
                Да <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                      data-bs-target="#RejectCandidateModal" @click="openRejectCandidateModal(item.id)">
                Нет <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>

        </td>
        <td>

          <div v-if="+item.without_kkk">
            <p class="text-success">Принято ускоренно</p>
          </div>
          <div v-else-if="item.reject_status==0&&item.confirm_status==0">
            <button type="button" class="btn btn-success" data-bs-toggle="modal"
                    data-bs-target="#ConfirmWithoutInterviewCandidateModal"
                    @click="openConfirmWithoutInterviewCandidateModal(item.user, item.disciplineVacancy.discipline_id)">
              Принять ускоренно <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            </button>
          </div>


        </td>


      </tr>
      </tbody>
    </table>
  </div>
  <!-- table end -->
</template>

<script>

  import ResumeInfoModal from '@/components/vacancy/ResumeInfoModal.vue'
  import ConfirmCandidateModal from '@/components/vacancy/ConfirmCandidateModal.vue'
  import ConfirmWithoutInterviewCandidateModal from '@/components/vacancy/ConfirmWithoutInterviewCandidateModal.vue'
  import RejectCandidateModal from '@/components/vacancy/RejectCandidateModal.vue'
  import { mapActions, mapMutations, mapState} from "vuex"


  export default {
    name: "DepartmentHeadVacancyApplications",
    components: {
      ResumeInfoModal,
      ConfirmCandidateModal,
      RejectCandidateModal,
      ConfirmWithoutInterviewCandidateModal
    },

    data() {
      return {
        page: +this.$route.query.page || 1,
        status: null,
        filterStatus: 'all'
      }
    },
    computed: {
      ...mapState('vacancy', ['userSyllabus']),
      filteredUserSyllabus() {
        if (this.filterStatus == 'confirmedStatus') {
          return this.userSyllabus.filter(i => i.confirm_status == 1);
        } else if (this.filterStatus == 'rejectedStatus') {
          return this.userSyllabus.filter(i => i.reject_status == 1);
        }
        return this.userSyllabus
      }
    },

    methods: {
      ...mapActions('vacancy', ['GET_USER_SYLLABUS']),
      ...mapMutations('vacancy', ['SET_RESUME_INFO_MODAL_USER_ID', 'SET_CONFIRM_CANDIDATE_MODAL_USER_SYLLABUS_ID',
        'SET_REJECT_CANDIDATE_MODAL_USER_SYLLABUS_ID', 'SET_CONFIRM_WITHOUT_INTERVIEW_USER_ID', 'SET_CONFIRM_WITHOUT_INTERVIEW_DISCIPLINE_ID']),
      ...mapMutations('userinfo', ['SET_USER_ID']),

      async changeFilterStatus(e) {

        this.filterStatus = e.target.value
      },
      getFio(user) {
        return `${user.lastname || ''} ${user.firstname || ''} ${user.middlename || ''}`
      },
      openResumeInfoModal(userId) {
        this.SET_RESUME_INFO_MODAL_USER_ID(userId)
      },
      openConfirmCandidateModal(userSyllabusId) {
        this.SET_CONFIRM_CANDIDATE_MODAL_USER_SYLLABUS_ID(userSyllabusId)
      },
      openConfirmWithoutInterviewCandidateModal(user_id, discipline_id) {
        console.log(user_id, 'user_id')
        console.log(discipline_id, 'discipline_id')
        this.SET_CONFIRM_WITHOUT_INTERVIEW_USER_ID(user_id)
        this.SET_CONFIRM_WITHOUT_INTERVIEW_DISCIPLINE_ID(discipline_id)
      },
      openRejectCandidateModal(userSyllabusId) {
        this.SET_REJECT_CANDIDATE_MODAL_USER_SYLLABUS_ID(userSyllabusId)
      },
      goToUser(userId) {
        this.SET_USER_ID(userId)
        this.$router.push('/user')
      }
    }
  }
</script>

<style scoped>

</style>