export function convertKkkInterviewTimestampToDate(timestamp) {
    let date = new Date(timestamp * 1000)
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hour = date.getHours()
    let minute = date.getMinutes()
    let monthZero = month < 10 ? '0' : ''
    let dayZero = day < 10 ? '0' : ''
    let hourZero = hour < 10 ? '0' : ''
    let minuteZero = minute < 10 ? '0' : ''
    return date.getFullYear() + '-' + monthZero + month + '-' + dayZero + day + "T" + hourZero + hour + ":" + minuteZero + minute

}