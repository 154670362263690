<template>


  <!-- Reject Candidate Modal -->
  <div class="modal fade" id="rejectCandidateModal" tabindex="-1"
       aria-labelledby="rejectCandidateModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="rejectCandidateModalLabel">Кандидат </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mt-4">
            <div class="col-md-3">
              <label for="comment">Комментарий:</label>
            </div>
            <div class="col-md-9">
          <textarea id="comment" class="form-control" v-model="rejectCandidateComment">
          </textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="rejectCandidate()">
            Отклонить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Отменить
          </button>

        </div>
      </div>
    </div>
  </div>
  <!-- Reject Candidate Modal -->

</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";


export default {
  name: "RejectCandidateModal",
  data() {
    return {
      page: +this.$route.query.page || 1,
      rejectCandidateComment: ''
    }
  },
  computed: {
    ...mapState('vacancy', ['userSyllabus']),
  },

  methods: {
    ...mapActions('vacancy', ['GET_USER_SYLLABUS', 'PUT_USER_SYLLABUS_REJECT_STATUS']),
    //...mapMutations('vacancy', ['UPDATE_CANDIDATE_COMMENT_DATA']),


    getFio(user) {
      return `${user.lastname || ''} ${user.firstname || ''} ${user.middlename || ''}`
    },
    async rejectCandidate() {
      let rejectCandidateComment = this.rejectCandidateComment
      await this.PUT_USER_SYLLABUS_REJECT_STATUS(rejectCandidateComment)
      let discipline_vacancy_id = this.userSyllabus[0].discipline_vacancy_id;

      await this.GET_USER_SYLLABUS({page: this.page, discipline_vacancy_id})
    }

  },

  async mounted() {

  }
}
</script>

<style scoped>

</style>