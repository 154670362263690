<template>
  <ResumeInfoModal v-bind:id="'ResumeInfoModal'"/>

  <!-- Select Kkk Interview Modal -->
  <div class="modal fade" id="selectKkkInterviewTimeModal" tabindex="-1"
       aria-labelledby="selectKkkInterviewTimeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="selectKkkInterviewTimeModalLabel">Выбрать дату проведения</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mt-4">
            <div class="col-md-3">
              Выберите дату:
            </div>
            <div class="col-md-9">
              <input type="date" class="form-control" v-model="interviewTime"
                     @change="changeKkkInterviewTime">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!--                  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="updateKkkInterviewTime()">-->
          <!--                    Обновить-->
          <!--                  </button>-->
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Потвердить
          </button>

        </div>
      </div>
    </div>
  </div>
  <!-- End Select Kkk Interview Modal -->


  <!-- Update Kkk Interview Modal -->
  <div class="modal fade" id="updateKkkInterviewTimeModal" tabindex="-1"
       aria-labelledby="updateKkkInterviewTimeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updateKkkInterviewTimeModalLabel">Выбрать дату проведения</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mt-4">
            <div class="col-md-3">
              Выберите дату:
            </div>
            <div class="col-md-9">
              <input type="date" class="form-control"
                     @change="changeNewKkkInterviewTime(index, $event)">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  @click="updateKkkInterviewTime()">
            Обновить
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Отменить
          </button>

        </div>
      </div>
    </div>
  </div>
  <!-- End Update Kkk Interview Modal -->


  <!-- table start -->
  <div class="table-responsive-md mt-4">
    <table class="table table-striped table-bordered text-center">
      <thead>
      <tr>
        <th scope="col">№</th>
        <th scope="col">ФИО</th>
        <th scope="col">Название дисциплины</th>
        <th scope="col">Резюме</th>
        <th scope="col">Дата проведения</th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="(item,index) in userSyllabus.filter(i=>i.confirm_status == 1)" :key="index">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ getFio(item.user0) }}</td>
        <td>{{ item.disciplineVacancy.discipline.name }}</td>
        <td>
          <a @click="goToUser(item.user)" class="link-style">
            резюме
          </a>
        </td>
        <td>

          <div v-if="item.kkkInterviewTimes.length">
            <input type="date" class="form-control"
                   :value="convertKkkInterviewTimestampToDate(item.kkkInterviewTimes[0].date)" disabled>
            <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                    data-bs-target="#updateKkkInterviewTimeModal"
                    @click="openUpdateKkkInterviewTimeModal(item.kkkInterviewTimes[0])">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
          </div>
          <div v-else>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#selectKkkInterviewTimeModal"
                    @click="openSelectKkkInterviewTimeModal(item.id)">
              Выбрать дату
            </button>
          </div>


        </td>


      </tr>
      </tbody>
    </table>
  </div>
  <!-- table end -->

  <div class="col-md-12 text-center">
    <button type="button" class="btn btn-primary" :disabled="loading" @click="submitKkkInterviewTime">
      Сохранить
    </button>
  </div>

</template>

<script>
  import ResumeInfoModal from '@/components/vacancy/ResumeInfoModal.vue'
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import {convertKkkInterviewTimestampToDate} from "@/utils/helpers/convertKkkInterviewTimestampToDate"


  export default {
    name: "HrVacancyApplications",
    components: {
      ResumeInfoModal
    },

    data() {
      return {
        page: +this.$route.query.page || 1,
        interviewUserId: null,
        interviewTime: null
      }
    },
    computed: {
      ...mapState('vacancy', ['userSyllabus', 'loading']),
    },

    methods: {
      ...mapActions('vacancy', ['GET_USER_SYLLABUS', 'POST_KKK_INTERVIEW_TIME', 'GET_KKK_INTERVIEW_TIME', 'PUT_KKK_INTERVIEW_TIME']),
      ...mapMutations('vacancy', ['SET_USER_SYLLABUS_ID', 'UPDATE_USER_KKK_INTERVIEW_TIME', 'SET_EDIT_KKK_INTERVIEW_TIME_DATA', 'UPDATE_NEW_USER_KKK_INTERVIEW_TIME', 'SET_RESUME_INFO_MODAL_USER_ID']),
      ...mapMutations('user', ['SET_KKK_INTERVIEW_ID']),
      ...mapMutations('userinfo', ['SET_USER_ID']),


      convertKkkInterviewTimestampToDate,
      getFio(user) {
        return `${user.lastname || ''} ${user.firstname || ''} ${user.middlename || ''}`
      },
      openResumeInfoModal(userId) {
        this.SET_RESUME_INFO_MODAL_USER_ID(userId)
      },

      async openSelectKkkInterviewTimeModal(id) {
        console.log(id, 'openSelectKkkInterviewTimeModal')
        this.interviewTime = null
        await this.SET_USER_SYLLABUS_ID(id);
      },
      async openUpdateKkkInterviewTimeModal(kkkInterviewTimeData) {
        await this.SET_EDIT_KKK_INTERVIEW_TIME_DATA(kkkInterviewTimeData)
      },
      changeKkkInterviewTime(e) {
        this.UPDATE_USER_KKK_INTERVIEW_TIME({value: e.target.value})
      },
      changeNewKkkInterviewTime(index, e) {
        this.UPDATE_NEW_USER_KKK_INTERVIEW_TIME({index, value: e.target.value})
      },
      submitKkkInterviewTime() {
        let discipline_vacancy_id = this.$route.query.discipline_vacancy_id || 0
        let page = 1
        this.POST_KKK_INTERVIEW_TIME()
          .then(res => {
            if (res.success == true) {
              this.GET_USER_SYLLABUS({page, discipline_vacancy_id})
              this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
            } else {
              let errorText = '';
              for (let err of res.errors[0]) {
                errorText += err.message + '\n'
              }
              this.$error({title: 'Добавление', text: errorText})
            }
          })
      },
      setKkkInterviewId(kkk_interview_id) {
        this.SET_EDIT_KKK_INTERVIEW_TIME_ID(kkk_interview_id)
      },
      async updateKkkInterviewTime() {
        let discipline_vacancy_id = this.$route.query.discipline_vacancy_id || 0
        let page = 1
        await this.PUT_KKK_INTERVIEW_TIME().then(res => {
          if (res.success == true) {
            this.GET_USER_SYLLABUS({page, discipline_vacancy_id})
            this.$message({title: 'Обновлене', text: 'Данные успешно обновились'})
          } else {
            let errorText = '';
            for (let err of res.errors[0]) {
              errorText += err.message + '\n'
            }
            this.$error({title: 'Обновление', text: errorText})
          }
        })

      },
      goToUser(userId) {
        this.SET_USER_ID(userId)
        this.$router.push('/user')
      }

    }
  }
</script>

<style scoped>

</style>